<template>
  <div class="tree__wrapper">
    <div>
      <Company
        :isCollapse="!isExpanded"
        :hasSub="parentCompany.children.length > 0"
        :companyName="parentCompany.text"
        :companyId="parentCompany.id"
        @left-click="isExpanded = !isExpanded"
        @expend="expendHandler"
        @select="chooseCompany"
      />
      <div class="sub" v-if="parentCompany.children.length" :style="!isExpanded ? 'height: 0' : ''">
        <ParentCompany
          :parentCompany="item"
          v-for="item in parentCompany.children"
          :key="item.id"
          @expend="expendHandler"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, inject, watch } from 'vue'
import Company from './SubCompany.vue'
export default {
  name: 'ParentCompany',
  components: {
    Company
  },
  props: {
    parentCompany: {
      type: Object,
      default() {
        return {}
      }
    },
    // 是否默认展开
    expand: {
      type: Boolean,
      default: true
    }
  },
  emits: ['select-company', 'expend'],
  setup(props, { emit }) {
    watch(
      () => props.expand,
      val => (state.isExpanded = val)
    )

    const state = reactive({
      isExpanded: true,
      companyName: '',
      companyId: ''
    })
    // 递归嵌套结构，所以使用依赖注入
    const updateCompanyId = inject('updateCompanyId')
    const updateCompanyName = inject('updateCompanyName')

    const chooseCompany = ({ name, id }) => {
      state.companyName = name
      state.companyId = id
      updateCompanyId(id)
      updateCompanyName(name)
    }

    // 展开操作，子级触发 expend，则父级全部展开
    const expendHandler = () => {
      state.isExpanded = true
      emit('expend')
    }
    // 收起操作
    const collapseHandler = () => {
      state.isExpanded = false
    }

    return {
      ...toRefs(state),
      chooseCompany,
      expendHandler,
      collapseHandler
    }
  }
}
</script>
<style scoped lang="scss">
.sub {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  margin-left: 16px;
}
</style>
