<template>
  <NavBar>
    <template #right>
      <div :class="['operate', { 'operate-active': selectedCompanyId }]" @click="confirmCompany">
        <span>确定</span>
      </div>
    </template>
  </NavBar>
  <div class="container">
    <van-search
      v-model="searchKey"
      placeholder="请输入搜索关键词"
      @update:model-value="searchCom"
      class="search-input"
    />
    <div class="show-company">
      <ParentCompany v-for="item in companyList" :key="item.id" :parentCompany="item" />
    </div>
  </div>
</template>
<script>
import { toRefs, provide, ref, onActivated } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import companyModule from './selectCompany'
import ParentCompany from '@/views/components/ParentCompany.vue'

export default {
  name: 'SelectCompany',
  components: {
    ParentCompany
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const moduleKey = route.params.section // 需要修改车牌的模块名
    const keep = route.params.keep // 是否需要保留车辆信息，默认不传即不保留
    const store = useStore()
    const { state, searchCom } = companyModule()
    const selectedCompanyId = ref('')
    const companyName = ref('')
    const updateCompanyId = com => {
      selectedCompanyId.value = com
    }
    const updateCompanyName = com => {
      companyName.value = com
    }
    // 初始化公司名和ID
    selectedCompanyId.value = store.state[moduleKey].companyId || ''
    companyName.value = store.state[moduleKey].companyName || ''

    // 缓存列表，每次 active 同步选中公司信息
    onActivated(() => {
      selectedCompanyId.value = store.state[moduleKey].companyId || ''
      companyName.value = store.state[moduleKey].companyName || ''
      state.searchKey = ''
      searchCom()
    })
    // 向下提供数据和方法，更新公司名称和公司ID
    provide('selectedCompanyId', selectedCompanyId)
    provide('updateCompanyId', updateCompanyId)
    provide('selectedCompanyName', companyName)
    provide('updateCompanyName', updateCompanyName)
    // 确认选中，返回上级
    const confirmCompany = () => {
      if (!selectedCompanyId.value) return
      let changeState = { companyName: companyName.value, companyId: selectedCompanyId.value }
      // 如果改变了厂区，并且不需要保留车辆信息，清空车辆信息
      if (selectedCompanyId.value !== store.state[moduleKey].companyId && !keep) {
        changeState.carNumber = ''
        changeState.deviceId = ''
      }
      store.commit(`${moduleKey}/updateState`, changeState)
      setTimeout(() => {
        state.searchKey = ''
        router.go(-1)
      }, 500)
    }
    return {
      ...toRefs(state),
      selectedCompanyId,
      confirmCompany,
      searchCom
    }
  }
}
</script>
<style lang="scss" scoped>
.operate {
  color: rgba(255, 255, 255, 0.52);
  &-active {
    color: rgb(255, 255, 255);
  }
}
.container {
  display: flex;
  flex-direction: column;
  .show-company {
    flex-grow: 1;
    overflow-y: scroll;
    padding-top: 8px;
  }
}
</style>
