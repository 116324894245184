import { reactive } from 'vue'
import http from '@/utils/request'
import { toTree } from '@/utils/common'

export default function () {
  const state = reactive({
    copyCompanys: [], // 用来保存筛选前的全部原始数据
    companyList: [],
    searchKey: ''
  })
  http({
    url: '/company/companytree.html',
    method: 'get'
  }).then(res => {
    if (res) {
      const companys = toTree(res)
      state.copyCompanys = deepClone(companys)
      state.companyList = companys
    }
  })
  const searchCom = () => {
    state.companyList = deepSearch(state.searchKey.trim(), deepClone(state.copyCompanys))
  }
  return {
    state,
    searchCom
  }
}
//深拷贝浅拷贝
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
// 查询公司名称
function deepSearch(val, origins) {
  let result = []
  for (let index = 0; index < origins.length; index++) {
    const el = origins[index]
    if (el.text.includes(val)) {
      result = result.concat(el)
    } else {
      if (el.children && el.children.length > 0) {
        result = result.concat(deepSearch(val, el.children))
      }
    }
  }
  return result
}
