<template>
  <!-- 根据选中值设置选中效果 -->
  <div class="company__wrapper" @click="rightClick" :id="companyId === selectedId ? 'selectedDom' : ''">
    <div :class="['icon', { icon__collapsed: isCollapse, icon__expanded: !isCollapse }]" @click.stop="leftClick">
      <!-- 子级展开 icon -->
      <van-icon name="arrow-up" v-show="hasSub" />
    </div>
    <div class="flex-main">
      {{ companyName }}
    </div>
    <div class="selection">
      <van-icon name="passed" :class="['selection__icon', { 'selection-active': companyId === selectedId }]" />
    </div>
  </div>
</template>
<script>
import { ref, inject, onActivated } from 'vue'
export default {
  name: 'SubCompany',
  props: {
    hasSub: {
      type: Boolean,
      required: true
    },
    // 默认全部展开
    isCollapse: {
      type: Boolean,
      default: true
    },
    companyName: {
      type: String,
      required: true
    },
    companyId: {
      type: String,
      required: true
    }
  },
  emits: ['left-click', 'expend', 'select'],
  setup(props, { emit }) {
    const isSelected = ref(false)
    const leftClick = () => {
      emit('left-click')
    }
    const rightClick = () => {
      emit('select', { name: props.companyName, id: props.companyId })
    }
    onActivated(() => {
      // 如果有选中，滚到选中项至可见位置
      if (props.companyId === selectedId.value) {
        const targe = document.querySelector('#selectedDom')
        targe.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
      }
    })
    // 多层嵌套结果，使用依赖注入获取选中
    const selectedId = inject('selectedCompanyId')
    return {
      isSelected,
      leftClick,
      rightClick,
      selectedId
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/style/variables.scss';
.company__wrapper {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  .icon {
    width: 36px;
    font-size: 20px;
    text-align: center;
  }

  .selection {
    font-size: 20px;
    width: 36px;
    text-align: center;
    &__icon {
      overflow: hidden;
      border-radius: 50%;
    }
    &-active {
      background-color: $blue;
      color: #fff;
    }
  }
  .icon__expanded {
    transform: rotate(0deg);
    transition: transform 0.3s ease-out;
  }
  .icon__collapsed {
    transform: rotate(180deg) translateZ(0);
    transition: transform 0.3s ease-in;
  }
}
</style>
