<template>
  <NavBar />
  <div class="create-company container">
    <div class="create-company__info">
      <div class="create-company__info__option">
        <van-field v-model="companyInfo.companyName" label="公司名称：" required />
        <van-field v-model="companyInfo.tel" label="电话号码：" required />
        <van-field v-model="companyInfo.email" label="公司邮箱：" required />
        <van-field v-model="companyInfo.province.text" label="省份：" readonly required @click="areaPicker = true" />
        <van-field v-model="companyInfo.city.text" label="城市：" readonly required @click="areaPicker = true" />
        <van-field v-model="companyInfo.district.text" label="市区：" readonly @click="areaPicker = true" />
        <van-field v-model="companyInfo.address" label="详细地址：" required />
      </div>
      <div class="create-company__submit">
        <van-button type="primary" size="large" @click="onSubmit" round>添加</van-button>
      </div>
    </div>
    <van-popup v-model:show="areaPicker" round position="bottom">
      <van-cascader
        v-model="state.cascaderValue"
        title="请选择所在地区"
        active-color="#1989fa"
        :value="selectedValue"
        :options="state.options"
        @close="areaPicker = false"
        @change="provinceSelectOnChange"
        @finish="provinceSelectFinished"
      />
    </van-popup>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import http from '@/utils/request'
export default {
  name: 'CreateCompany',
  setup() {
    const router = useRouter()
    const companyInfo = reactive({
      companyName: '',
      tel: '',
      email: '',
      province: { text: '', value: '' },
      city: { text: '', value: '' },
      district: { text: '', value: '' },
      address: ''
    })
    const selectedValue = ref('')
    const state = reactive({
      cascaderValue: '',
      options: []
    })
    const areaPicker = ref(false)
    onMounted(() => {
      try {
        initProvince()
      } catch (error) {
        console.warn(error)
      }
    })
    const provinceSelectOnChange = ({ selectedOptions }) => {
      if (selectedOptions.length === 1) {
        companyInfo.province = selectedOptions[0]
        companyInfo.city = ''
        companyInfo.district = ''
      } else if (selectedOptions.length === 2) {
        companyInfo.province = selectedOptions[0]
        companyInfo.city = selectedOptions[1]
        companyInfo.district = ''
      }
    }
    const provinceSelectFinished = ({ selectedOptions }) => {
      areaPicker.value = false
      companyInfo.province = selectedOptions[0]
      companyInfo.city = selectedOptions[1]
      companyInfo.district = selectedOptions[2]
    }
    const onSubmit = () => {
      submitCompanyInfo(companyInfo).then(res => {
        if (res.success === true) {
          Toast('创建成功')
          router.go(-1)
        }
      })
    }
    async function initProvince() {
      let data = await http('/company/regionList.html')
      let option = formatProvince(data.data)
      state.options = option
    }
    return {
      state,
      companyInfo,
      areaPicker,
      selectedValue,
      provinceSelectOnChange,
      provinceSelectFinished,
      onSubmit
    }
  }
}

function formatProvince(regionList) {
  let provinceArr = regionList
    .filter(el => el.level === 2)
    .map(item => {
      let { areaName, id } = item
      return { text: areaName, value: id }
    })

  provinceArr.forEach(province => {
    let cityChildren = regionList
      .filter(re => re.level === 3 && re.parentId === province.value)
      .map(city => {
        let { areaName, id } = city
        return { text: areaName, value: id }
      })
    province.children = cityChildren
    province.children.forEach(city => {
      let districtChildren = regionList
        .filter(region => region.level === 4 && region.parentId === city.value)
        .map(district => {
          let { areaName, id } = district
          return { text: areaName, value: id }
        })
      city.children = districtChildren
    })
  })
  return provinceArr
}
async function submitCompanyInfo(info) {
  let { companyName, tel, email, province, city, district, address } = info
  if (!companyName || !companyName.trim()) {
    Toast('请输入公司名称')
    return
  } else if (!tel || !tel.trim()) {
    Toast('请输入手机号码')
    return
  } else if (!email || !email.trim()) {
    Toast('请输入公司邮箱')
    return
  } else if (!province.text || !province.text.trim()) {
    Toast('请选择所在省份')
    return
  } else if (!city.text || !city.text.trim()) {
    Toast('请选择所在城市')
    return
  } else if (!address || !address.trim()) {
    Toast('请输入详细地址')
    return
  }
  let telreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
  if (!telreg.test(tel.trim())) {
    Toast('请填写正确的手机号码')
    return
  }
  let provinceId = province.value
  let cityId = city.value
  let townId
  if (district && district.value) {
    townId = district.value
  }
  let params = { companyName, tel, email, provinceId, cityId, townId, address }
  let data = await http.post('company/add.html', params)
  return data
}
</script>
<style lang="scss" scoped>
.create-company {
  width: 100%;

  &__submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 0 16px;
  }
}
</style>
